<template>
  <div class="InMailDetail">
    <div v-loading="loading"></div>
    <div v-if="clientWidth < 751" class="InMailDetail-content">
      <div class="InMailList-d-s-title">系统通知</div>
      <div class="InMailList-d-s-deta co-999 tc">{{InMallDetail.dataTimeString}}</div>
      <div class="InMailList-d-s-content flex">
        <div class="InMailList-d-s-content-icon flex1">
          <img src="@/assets/PC/home/znx.png">
        </div>
        <div class="InMailList-d-s-content-text">
          <b>亲爱的{{ Info.name }}，你好：</b> 
          <p v-html="InMallDetail.content"></p> 
          <div class="san0"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetSystemMessageInfo } from "@/api/profile.js";

export default {
  name: "InMailDetail",
  data() {
    return {
      loading: true,                           // !loading
      Info: this.$store.state.Infos,           // !个人信息
      DetailId: '',                            // !详情Id
      InMallDetail: {},                        // !数据对象
    };
  },
  mounted() {
    if(!this.$store.state.token) {
      this.$router.push('/');
    }else {
      if(this.$store.state.clientWidth > 750) {
        this.$router.push('/Home/InMail');
      }else {
        this.DetailId = this.$route.query.id;
        this.GetInit();
      }
    }
  },
  methods: {
    GetInit() {
      const Id = {messageId: this.DetailId};
      GetSystemMessageInfo(Id).then(res => {
        if(res.status == 1) {
          this.InMallDetail = res.data;
        } else {
          this.$toast(res.message);
        }
        this.loading = false;
      })
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
    Infos() {
      return this.$store.state.Infos;
    },
  },
  watch: {
    $route: {
      handler(val) {},
      deep: true,
    },
    Infos(val) {
      this.Info = val;
    },
  },
};
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.InMailDetail {
  .InMailDetail-content { padding: vw(40); background: #F2F5F7; min-height: calc( 100vh - 13.3333vw);}
  .InMailList-d-s-title { font-size: vw(29); padding-bottom: vw(18); border-bottom: 1px #7E3D9D solid; margin-bottom: vw(40); }
  .InMailList-d-s-deta { margin-bottom: vw(30); }
  .InMailList-d-s-content-icon { min-width: vw(64); width: vw(64); height: vw(64); border-radius: 50%; border: 1px #ccc solid; margin-right: vw(30); }
  .InMailList-d-s-content-icon img {width: vw(40); height: vw(40);}

  .InMailList-d-s-content-text { background: #fff; width: 100%; padding: vw(30); border-radius: vw(10); position: relative;}
  .san0 {width: 0;height: 0;border-top: vw(14) solid transparent;border-right: vw(30) solid #fff;border-bottom: vw(14) solid transparent; position: absolute; left: vw(-14); top: vw(15);}

  .InMailList-d-s-content-text p {margin-top: vw(10);}
}

@media (max-width: 750px) {
}
</style>